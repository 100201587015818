<template>
  <b-navbar
    toggleable="lg"
    class="navbar-custom"
    :class="{'navbar-transparent': trasparentHeader}"
  >
    <b-navbar-brand :to="{ path: '/' }">
      <b-img
        fluid
        class="nav-img card-img-top logo_desktop d-none d-md-block"
        :src="require('@/assets/images/app-images/MOM_Logo.png')"
        alt="MOM Logo"
      />
      <b-img
        fluid
        class="nav-img card-img-top logo_mobile d-block d-md-none"
        :src="require('@/assets/images/logo/mom-logo-icon-72.png')"
        alt="MOM Logo Icon"
      />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse
      v-if="!removeMenu"
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item :to="{ path: '/user' }">
          <div class="double_line_header">
            <span class="header_line_1">{{ $t('MOM AI') }}</span>
            <span class="header_line_2">{{ $t('AI CONTENT GENERATOR') }}</span>
          </div>
        </b-nav-item>
        <b-nav-item :to="{ path: '/provider' }">
          <div class="double_line_header">
            <span class="header_line_1">{{ $t('MOM PROVIDER') }}</span>
            <span class="header_line_2">{{ $t('BOOKING MANAGEMENT SYSTEM') }}</span>
          </div>
        </b-nav-item>
        <b-nav-item :to="{ path: '/shop' }">
          <div class="double_line_header">
            <span class="header_line_1">{{ $t('MOM SHOP') }}</span>
            <span class="header_line_2">{{ $t('ORDER MANAGEMENT SYSTEM') }}</span>
          </div>
        </b-nav-item>
        <b-nav-item-dropdown class="text-uppercase d-flex align-self-center">
          <template #text>
              {{ $t('Resources') }}
          </template>
          <b-dropdown-item
            :to="{name: 'affilate'}"
          >
            {{ $t('Affilate Program') }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{
              path: '/blog',
            }"
          >
            {{ $t('Blog') }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{
              path: '/faq',
            }"
          >
            {{ $t('FAQ') }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{
              path: '/knowledge-base',
            }"
          >
            {{ $t('Knowledge Base') }}
          </b-dropdown-item>
          <b-dropdown-item
            :to="{name: 'contact-us'}"
          >
            {{ $t('Contact Us') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <locale class="landing-page-locale-translate" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { BImg, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BImg,
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    Locale,
    BNavItemDropdown,
    BDropdownItem,
  },
  props: {
    navClass: {
      type: String,
      default: 'dark',
    },
    trasparentHeader: {
      type: Boolean,
      default: false,
      required: false,
    },
    removeMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    // showWidget() {
    //   if (window.Tawk_API && window.Tawk_API.maximize) {
    //     window.Tawk_API.maximize()
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
#nav-collapse {
  justify-content: flex-end;
}
#nav-collapse .nav-link, #nav-collapse .dropdown-grouped, #nav-collapse .dropdown-grouped {
  text-transform: uppercase !important;
}

.navbar-absolute img {
  padding: 15px;
  width: 75%;
}
.navbar-light .nav-img {
  padding: 15px;
  width: 75%;
}

.navbar-absolute {
  position: absolute;
  width: 100%;
  background: transparent !important;
}

.navbar-nav li{
  padding: 0 20px;
}

.top-header-bar-super.navbar-absolute {
  background: #ffffff80 !important;
  box-shadow: 0 5px 20px #eaecef;
}

.user-landing-page .top-header-bar-super #nav-collapse .nav-link {
  color: #000 !important;
  text-shadow: 2px 1px #ccc;
}
.navbar-custom #nav-collapse .nav-link,
.navbar-custom .b-nav-dropdown .nav-link {
  color: #000 !important;
}
.bg-dark-theme #nav-collapse .nav-link {
  color: #FFF !important;
}
</style>

<style lang="scss">
.bg-dark-theme #nav-collapse .nav-link,
.bg-dark-theme .b-nav-dropdown .nav-link {
  color: #FFF !important;
}

.new-langing-ui .navbar-nav .b-nav-dropdown li {
  padding: 0;
}

.new-langing-ui .dropdown-menu .dropdown-item {
  font-size: 16px;
}

.landing-page-locale-translate {
  .text-body {
    color: #fff !important;
    font-size: 1.25rem;
    display: inline-block !important;
  }
  margin-top: 5px;
  margin-bottom: 10px;

  img {
    width: auto !important;
  }
}
.navbar-custom{
  .b-nav-dropdown {
    .nav-link{
      font-size: 1rem;
    }
  }
  .nav-link{
    font-size: 1rem;
  }
}

.double_line_header{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .navbar-custom #nav-collapse .nav-link,
  .navbar-custom .b-nav-dropdown .nav-link {
    font-size: 1rem !important;
  }
  
  .navbar-custom{
    .b-nav-dropdown {
      .nav-link{
        font-size: 14px;
      }
    }
  }
  .landing-page-locale-translate {
    .text-body {
      font-size: 14px;
    }
  }

  .navbar-custom{
    text-align: left;
    #nav-collapse {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .b-nav-dropdown {
      text-transform: uppercase;
    }
  }
  .navbar-transparent{
    #nav-collapse {
      background-color: #040612;
    }
  }
  .navbar-toggler{
    border: none;
  }
  .navbar-toggler-icon{
    background-color: transparent;
    background-image: url('~@/assets/images/app-icons/header-dd-icon.png') !important;
    background-position: center center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-size: cover; /* Ensures the image covers the entire element */
    color: #FFFFFF;
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler-icon{
    background-color: transparent;
    background-image: url('~@/assets/images/app-icons/header-dd-icon.png') !important;
    background-position: center center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-size: cover; /* Ensures the image covers the entire element */
    color: #FFFFFF;
    border-color: transparent;
  }
  .navbar-dark .navbar-toggler{
    border-color: transparent;
  }
  .navbar{
    padding: 0 0 !important;
  }
  .navbar-custom img{
    padding: 6px !important;
  }
  .navbar-custom #nav-collapse .nav-link, 
  .navbar-custom .b-nav-dropdown .nav-link{
    font-size: 14px !important;
  }
}
</style>
